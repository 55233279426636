import React, { useState } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useField from 'inc/hooks/useField';
import FieldWrapper from './FieldWrapper';

type Props = {
  helperText?: React.ReactNode | string,
  label?: string,
  name: string,
  options: {
    id: string,
    inputProps?: ComponentInputProps,
    name: string,
  }[],
  required?: boolean,
};

function CheckboxGroup({ helperText, label, name, options, required }: Props) {
  const [checked, setChecked] = useState<string[]>([]);
  const { error, setValue } = useField(name, {
    callback: (data: Document.Base) => {
      const value = data[name].map((v: string | number) => v.toString());
      setChecked(value);
      setValue(value, !!error);
    }
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist && e.persist();
    let value = [];
    const targetId = e.target.value;
    if (checked.includes(targetId)) {
      value = checked.filter((id) => id !== targetId);
    }
    else {
      value = [...checked, targetId];
    }
    setChecked(value);
    setValue(value, !!error, true);
  }
  if (process.env.REACT_APP_TESTING) {
    options = options.map(option => {
      return {...option, inputProps: {
        className: 'reset',
        'data-testid': `${name}-${option.id}-input`,
      }};
    });
  }
  return (
    <FieldWrapper
      helperText={helperText}
      label={label}
      name={name}
      required={required}
    >
      <FormGroup>
        {options.map(option => {
          return (
            <FormControlLabel
              control={
                <MuiCheckbox
                  checked={checked.includes(option.id)}
                  color="primary"
                  inputProps={option.inputProps || {}}
                  name={name}
                  onChange={onChange}
                  value={option.id}
                />
              }
              key={option.id}
              label={option.name}
            />
          )
        })}
      </FormGroup>
    </FieldWrapper>
  );
}

export default CheckboxGroup;

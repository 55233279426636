import React, { useEffect, useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { Theme } from '@material-ui/core';
import api from 'services/api';
import clsx from 'clsx';
import {
  State,
  TabPanel
} from 'components/Display';
import {
  AssetImage,
  Autocomplete,
  Checkbox,
  FieldWrapper,
  Hidden,
  Reward,
  RichEditor,
  Select,
  TextField
} from 'components/Form';
import Message from 'containers/Workflow/Message';
import { PageBlock } from 'components/Layout';
import useFormCrud from 'inc/hooks/useFormCrud';
import useObservable from 'inc/hooks/useObservable';
import useCompanyId from 'inc/hooks/useCompanyId';
import useConfigAsync from 'inc/hooks/useConfigAsync';
import useScroll from 'inc/hooks/useScroll';
import { BrandSchema } from 'inc/validation';
import { Grid, GridContainer } from 'components/Layout';
import countries from 'data/countries.json';
import naics from 'data/naics.json';
import merchantCategories from 'data/merchantCategories.json';
import categories from 'data/brandCategories.json';
import { ServiceList } from 'containers';
import usStates from 'data/usStates.json';
import customStyles, { newStyles } from 'theme/styles';
import auth0 from 'services/Auth0';
import event from 'services/EventManager';
import helperTexts from 'inc/constants/fields/brand';
import moment from 'moment';
import { VALUE_NULL, FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import moderationStates from 'data/states.json';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Brand>;

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  ...newStyles(theme),
  brandHero: {
    position: 'relative',
    marginBottom: 29,
    minHeight: 76,
  },
  brandHeroImage: {
    '& img': {
      position: 'relative',
      zIndex: 0,
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
    },
    '&:before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.custom.slate,
      opacity: 0.75,
      zIndex: 1,
    }
  },
  brandHeroLogo: {
    padding: 10,
    backgroundColor: theme.custom.white,
    width: 193,
    height: 76,
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 2,
    marginLeft: -96,
    marginTop: -38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: 173,
      maxHeight: 56
    }
  },
  brandHeroCaption: {
    position: 'absolute',
    left: 0,
    top: -21,
    backgroundColor: theme.custom.orange,
    padding: 10,
    color: theme.custom.black,
    fontSize: 16,
    zIndex: 3
  },
  brandContent: {
    maxWidth: 600,
    margin: '0 auto'
  },
}));

const BrandCreate = ({ history, match }: Props) => {
  const classes = useStyles();
  const { setTriggered: setErrorsTriggered } = useScroll('.field-error');
  const brandId = match.params['brandId'] || '';
  const saveLoading = useObservable('page.loading');
  const [activeStep, setActiveStep] = useState(0);
  const [assets, setAssets] = useState({});
  const [states, setStates] = useState(moderationStates);
  const platformUrl = useConfigAsync('platformUrl');
  const clientCompanyId = useCompanyId();
  const {
    handleEditSubmit,
    docId,
    methods,
    triggerValidation,
    values,
  } = useFormCrud({
    id: brandId,
    revisionId: match.params['revisionId'] || '',
    success: () => {
      if (!brandId) {
        setActiveStep(activeStep + 1);
      }
      else {
        history.push(`/brands/edit/${brandId}`);
      }
    },
    path: 'brands',
    schema: BrandSchema
  });
  const onAssetChange = (name: string) => (file: Data) => {
    setAssets(assets => ({...assets, [name]: file}));
  }
  const companyId = methods.watch('company') || clientCompanyId;
  const data = values[FORM_STATE_DATA] || {};
  const dataLoaded = !!values[FORM_STATE_LOADED];
  const config = {
    prepare: async (values: Document.Base) => {
      const billing_info = values?.billing_info ?? {};
      const updateOptions: string[] = data['meta.state.update'] || [];
      const state = updateOptions.includes(values?.state) ? values?.state : updateOptions[0];
      return {
        ...values,
        billing_info: !billing_info.__use_company ? billing_info : VALUE_NULL,
        state: auth0.isAdmin() ? state : 'ready_for_review',
      }
    },
    requests: {
      after: (brand: Document.Base) => {
        const requests: Promise<any>[] = [];
        if (docId) {
          if (!auth0.canFetch('brands')) {
            requests.push(auth0.getEntities([brand.id], 'brands', true));
          }
        }
        else {
          Object.keys(assets).forEach(assetName => {
            const asset = (assets as Data)[assetName];
            asset.id && asset.new && requests.push(api.path('assets').save({...asset, brand: brand.id}, asset.id));
          });
        }
        return requests;
      }
    }
  };
  const steps = [
    'Brand Info',
    'Brand Assets',
    'Submit for Review',
  ];
  useEffect(() => {
    if (dataLoaded) {
      if (data['meta.state.update']) {
        const update = data['meta.state.update'] || [];
        setStates(moderationStates.filter(state => update.includes(state.id)));
      }
    }
  }, [dataLoaded]);
  useEffect(() => {
    // If no id means its a form used for document creation.
    if (!docId) {
      const eventContext = { brand: values, stepName: steps[activeStep] };
      steps[activeStep] && event.dispatch('BRAND_CREATION_STEP_VIEWED', eventContext);
      activeStep > 0 && event.dispatch('BRAND_CREATION_STEP_COMPLETED', {
        ...eventContext,
        stepName: steps[activeStep - 1]
      });
    }
  }, [activeStep]);
  const fieldsToValidate = [
    [
      { name: 'name' },
      { name: 'company' },
      { name: 'content.country' },
      { name: 'content.naics_category' },
      { name: 'billing_info.email' },
      { name: 'billing_info.phone' },
      { name: 'billing_info.address.line1' },
      { name: 'billing_info.address.city' },
      { name: 'billing_info.address.state' },
      { name: 'billing_info.address.postal_code' },
      { name: 'content.category' },
    ],
    [
      { name: 'content.headline' },
      { name: 'content.description' },
      { name: 'content.logo.asset' },
      { name: 'content.logo.alt' },
      { name: 'content.hero.asset' },
      { name: 'content.hero.alt' },
    ],
  ];
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleWrapper}>
            <Typography
              component="h2"
              variant="h2"
            >
              {!docId ? 'Create Brand' : 'Edit Brand'}
            </Typography>
            {!!data['id'] && (
              <State
                className={classes.state}
                state={moderationStates.find(state => data['state'] === state.id) as ModerationState}
              />
            )}
          </div>
          <Typography
            component="p"
            variant="body2"
          >
            {helperTexts.sub_title}
          </Typography>
          {!!data['id'] && (
            <Typography
              className={classes.lastUpdated}
              component="h2"
              variant="h2"
            >
              Last Updated: <span>{moment.unix(data['updated.on'] / 1000).format('MM/DD/YYYY - HH:mm')}</span>
            </Typography>
          )}
        </CardContent>
        {docId && 'needs_work' === data['state'] && (
          <Message>
            {(data['history'] ?? [])[0]?.comment || data['revision_log']}
          </Message>
        )}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <FormContext {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleEditSubmit(config))}
          >
            <TabPanel
              index={0}
              value={activeStep}
            >
              <div className={classes.formContent}>
                {!!docId && auth0.isAdmin() && (
                  <PageBlock>
                    <div className={classes.settings}>
                      <Typography
                        className={classes.settingsTitle}
                        component="h3"
                        variant="h3"
                      >
                        Brand settings
                      </Typography>
                      <PageBlock adminView>
                        <Select
                          defaultValue="draft"
                          label="State"
                          name="state"
                          options={states}
                        />
                        {'needs_work' === values['state'] && (
                          <TextField
                            label="Comment"
                            multiline
                            name="log"
                            required
                            rows={2}
                            rowsMax={4}
                          />
                        )}
                      </PageBlock>
                      <PageBlock adminView>
                        <GridContainer>
                          {!!platformUrl && (
                            <Grid col={7}>
                              <TextField
                                className={classes.domainUrl}
                                disabled
                                label="Domain URL"
                                name="domain_url"
                                value={platformUrl.split('/b2b/')[0]}
                                variant="filled"
                              />
                            </Grid>
                          )}
                          <Grid col={platformUrl ? 5 : 12}>
                            <TextField
                              label="Custom URL Path"
                              name="content.url_alias"
                              placeholder="/"
                            />
                          </Grid>
                        </GridContainer>
                      </PageBlock>
                    </div>
                  </PageBlock>
                )}
                {auth0.isAdmin() && (
                  <PageBlock>
                    <ServiceList
                      component={(items, loading) =>
                        <Select
                          description="Which company will this brand be associated with?"
                          disabled={loading || !!docId}
                          label="Company"
                          name="company"
                          options={items}
                        />
                      }
                      path="companies"
                      waitForLoad={false}
                    />
                  </PageBlock>
                )}
                {!!companyId && !auth0.isAdmin() && (
                  <PageBlock>
                    <Hidden
                      name="company"
                      value={companyId}
                    />
                  </PageBlock>
                )}
                {!auth0.isAdmin() && (
                  <PageBlock>
                    <Hidden
                      name="state"
                      value="ready_for_review"
                    />
                  </PageBlock>
                )}
                <PageBlock>
                  <TextField
                    helperText={helperTexts.name}
                    label="Brand Name"
                    name="name"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <Autocomplete
                    defaultValue={countries.find(country => country.id === 'USA')}
                    description={helperTexts.country}

                    label="Country"
                    name="content.country"
                    options={countries}
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <Autocomplete
                    description={helperTexts.mcc_category}
                    label="Merchant Category Code (“MCC”)"
                    name="content.mcc_category"
                    options={merchantCategories}
                  />
                </PageBlock>
                <PageBlock>
                  <Autocomplete
                    description={helperTexts.naics_category}
                    label="NAICS Code"
                    name="content.naics_category"
                    options={naics}
                  />
                </PageBlock>
                <PageBlock>
                  <FieldWrapper
                    helperText={helperTexts.address}
                    label="Brand Address"
                    name="billing_info.__use_company"
                  >
                    <Checkbox
                      defaultChecked={!data['billing_info.address.line1'] || !!data['billing_info.__use_company']}
                      label="Same As company Address"
                      name="billing_info.__use_company"
                    />
                  </FieldWrapper>
                </PageBlock>
                {!values['billing_info.__use_company'] && (
                  <>
                    <PageBlock>
                      <TextField
                        label="Address Line 1"
                        name="billing_info.address.line1"
                        required
                      />
                    </PageBlock>
                    <PageBlock>
                      <TextField
                        label="Apt, Floor, suite, etc. (Optional)"
                        name="billing_info.address.line2"
                      />
                    </PageBlock>
                    <PageBlock>
                      <TextField
                        inputProps={{ maxLength: 5 }}
                        label="Postal Code"
                        name="billing_info.address.postal_code"
                        required
                      />
                    </PageBlock>
                    <PageBlock>
                      <TextField
                        label="City"
                        name="billing_info.address.city"
                        required
                      />
                    </PageBlock>
                    <PageBlock>
                      <Select
                        label="State"
                        name="billing_info.address.state"
                        options={usStates}
                        required
                      />
                    </PageBlock>
                    <PageBlock>
                      <TextField
                        label="Contact Email"
                        name="billing_info.email"
                        required
                      />
                    </PageBlock>
                    <PageBlock>
                      <TextField
                        label="Contact Phone Number"
                        name="billing_info.phone"
                        required
                      />
                    </PageBlock>
                  </>
                )}
                <PageBlock adminView>
                  <Autocomplete
                    defaultValue={[]}
                    description="To add a new keyword press Enter."
                    freeSolo
                    label="Keywords"
                    multiple
                    name="content.keywords"
                    options={[]}
                    renderTags={(value: string[], getTagProps: any) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          key={index}
                          label={option}
                          variant="outlined"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    style={{ width: '100%' }}
                  />
                </PageBlock>
                <PageBlock adminView>
                  <Checkbox
                    label="Featured"
                    name="content.featured"
                  />
                  <Reward
                    categories={categories}
                    name="content.category"
                  />
                </PageBlock>
                <PageBlock adminView>
                  <Checkbox
                    label="Enable Page"
                    name="content.page_enabled"
                  />
                  <TextField
                    label="URL Alias"
                    name="content.url_alias"
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={1}
              value={activeStep}
            >
              <div className={classes.formContent}>
                <PageBlock>
                  {(!!docId && !!data['company'] || !!companyId) && (
                    <AssetImage
                      entityId={docId || companyId}
                      entityType={docId ? 'brand' : 'company'}
                      helperText={helperTexts.logo}
                      label="Brand Logo"
                      name="content.logo"
                      onChange={onAssetChange('content.logo')}
                      required
                    />
                  )}
                </PageBlock>
                <PageBlock>
                  {(!!docId && !!data['company'] || !!companyId) && (
                    <AssetImage
                      entityId={docId || companyId}
                      entityType={docId ? 'brand' : 'company'}
                      helperText={helperTexts.hero}
                      label="Brand Landing Page Hero Image"
                      name="content.hero"
                      onChange={onAssetChange('content.hero')}
                    />
                  )}
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={helperTexts.headline}
                    label="Headline"
                    name="content.headline"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <RichEditor
                    description={helperTexts.description}
                    label="Description"
                    name="content.description"
                    required
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={2}
              value={activeStep}
            >
              <PageBlock>
                <div className={classes.brandHero}>
                  {values['content.hero.__preview'] && (
                    <div className={classes.brandHeroImage}>
                      <img
                        src={values['content.hero.__preview']}
                      />
                    </div>
                  )}
                  <div className={classes.brandHeroLogo}>
                    <img
                      src={values['content.logo.__preview']}
                    />
                  </div>
                  <div className={classes.brandHeroCaption}>
                    {'BRAND PAGE PREVIEW'}
                  </div>
                </div>
                <div className={classes.brandContent}>
                  <Typography
                    component="h2"
                    variant="h2"
                  >
                    {values['content.headline']}
                  </Typography>
                  <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: values['content.description'] }}
                  />
                </div>
              </PageBlock>
            </TabPanel>
            <TabPanel
              index={3}
              value={activeStep}
            >
              <div className={classes.formContent}>
                <PageBlock>
                  <Typography
                    component="h2"
                    paragraph
                    variant="h5"
                  >
                    Congrats! Your new brand is submitted for review.
                  </Typography>
                  <div style={{ paddingLeft: 48, paddingRight: 48, paddingTop: 16 }}>
                    <Typography
                      component="h2"
                      paragraph
                      variant="h5"
                    >
                      What now?
                    </Typography>
                    <Typography
                      component="p"
                      paragraph
                      variant="body1"
                    >
                      1. We are reviewing your brand, this final review ensures our partnership is set up for success.
                      Our review typically takes about 1-3 hours, although it can take up to 24 hours.
                      We’ll send you an email when your review is complete.
                    </Typography>
                    <Typography
                      component="p"
                      paragraph
                      variant="body1"
                    >
                      2. To check the status of your brand’s review, go to <Link to="/brands">manage your brand</Link>.
                    </Typography>
                  </div>
                </PageBlock>
              </div>
            </TabPanel>
            <div className={classes.actions}>
              {(activeStep > 0 && activeStep < 3) ? (
                <Button
                  className={clsx(classes.button, classes.formButtonMedium)}
                  color="primary"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveStep(activeStep - 1);
                  }}
                  variant="outlined"
                >
                  Back
                </Button>
              ) : null}
              {activeStep < 2 ? (
                <Button
                  className={clsx(classes.button, classes.formButtonLarge)}
                  color="primary"
                  data-testid="submit-next"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const result = await triggerValidation(fieldsToValidate[activeStep], true);
                    result ? setActiveStep(activeStep + 1) : setErrorsTriggered(triggered => triggered + 1);
                  }}
                  variant="contained"
                >
                  Go to the next step &gt;
                </Button>
              ) : null}
              {activeStep === 2 && (
                <>
                  <Button
                    className={classes.formButtonLarge}
                    color="primary"
                    data-testid="submit-save"
                    disabled={saveLoading}
                    type="submit"
                    variant="contained"
                  >
                    Save
                    {saveLoading && (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={24}
                      />
                    )}
                  </Button>
                </>
              )}
            </div>
          </form>
        </FormContext>
      </Card>
    </div>
  );
};

export default withRouter(BrandCreate);
